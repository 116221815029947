.page-print {
    .logo {
        display: block;
        float: none;
        text-align: left;
        font-size: 0px;
    }
}

@media print {
    * {
        -webkit-filter: none !important; // Use in 41 Chrome
        background: transparent !important;
        color: #000 !important;
        filter: none !important;
        text-shadow: none !important;
    }

    //  Black prints faster:h5bp.com/s
    a,
    a:visited {
        text-decoration: underline !important;
    }

    //  Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        border: 1px solid #666;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    .table-wrapper table {
        width: 100%;

        td {
            width: auto;
        }

        table-layout: fixed;

        &>tfoot>tr:first-child {
            border-top: 1px solid #666;
        }
    }

    .box,
    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1cm;
    }

    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .block-content {
        page-break-before: avoid;
    }

    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }

    .nav-toggle {
        display: none !important;
    }

    .sidebar,
    .nav-sections,
    .header.content>*[class],
    .panel.wrapper>*[class],
    .footer.content>*[class] {
        display: none;
    }

    .logo,
    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }

    .order-details-items {
        .order-items {
            .order-gift-message {
                &:not(.expanded-content) {
                    height: auto;
                    visibility: visible;
                }
            }
        }
    }

    .column.main {
        float: none !important;
        width: 100% !important;
    }

    .breadcrumbs {
        margin: 0 auto;
        text-align: right;
    }

    .footer.content {
        padding: 0;
    }

    .hidden-print {
        display: none !important;
    }

    header,
    footer {
        display: none
    }

    .navbar-brand {
        height: auto;
    }
    
    body #maincontent h1 {
        margin-bottom: 20px;
        font-size: 30px;
    }

    .label {
        border: none;
    }

    .actions-toolbar,
    .page-footer-before {
        display: none;
    }


  *,
  *:before,
  *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
